import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import * as Icon from 'react-feather'

import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'
import { ModalClose } from './ModalClose'
import { ModalIBE } from './ModalIBE'
import { Navigation } from './Navigation'

export interface Props {
  address?: string
  benefitsTitle?: string
  benefitsDescription?: string
  benefitsChecklist?: string[]
  bookButtonImage?: string
  country?: string
  email?: string
  IBEID?: number
  facebookURL?: string
  instagramURL?: string
  logo: { [key: string]: any }
  phone?: string
  postalCode?: string
  languageCode: string
  languagePrefix: string | null
  languagesList: LanguageItemProps[]
  offersButton?: MenuItemProps[]
  offersButtonImage?: string
  menuItems: MenuItemProps[]
  pageID?: string
  locality?: string
  siteName?: string
}

export const Header = memo(function Header({
  address,
  benefitsTitle,
  benefitsDescription,
  benefitsChecklist,
  bookButtonImage,
  country,
  email,
  facebookURL,
  instagramURL,
  IBEID,
  logo,
  phone,
  postalCode,
  languageCode,
  languagePrefix,
  languagesList,
  offersButton,
  offersButtonImage,
  menuItems,
  pageID,
  locality,
  siteName,
}: Props) {
  const [scroll, setScroll] = useState(false)
  const [navigationStatus, setNavigationStatus] = useState(false)
  const [modalIBEStatus, setModalIBEStatus] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > 400)
    }

    if (window.innerWidth > 1023) {
      enableBodyScroll(document.body)
    } else {
      document.body.style.overflow = 'auto'
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head dial={5} row space="between">
          <Contacts row>
            {address ? (
              <ContactsItem dial={5} row>
                <Icon.MapPin />
                <ContactsLabel>{`${address} - ${postalCode} ${locality} ${country}`}</ContactsLabel>
              </ContactsItem>
            ) : null}
            {phone ? (
              <ContactsItem dial={5} row>
                <Icon.Phone />
                <ContactsAnchor href={`tel:${phone}`}>{phone}</ContactsAnchor>
              </ContactsItem>
            ) : null}
            {email ? (
              <ContactsItem dial={5} row>
                <Icon.Mail />
                <ContactsAnchor href={`mailto:${email}`}>
                  {email}
                </ContactsAnchor>
              </ContactsItem>
            ) : null}
          </Contacts>
          {languagesList && languagesList.length > 1 ? (
            <Languages>
              {languagesList
                .filter((t) => t.pageID === pageID)
                .map((item, index) => (
                  <LanguageItem key={index} {...item} />
                ))}
            </Languages>
          ) : null}
        </Head>
        <Foot className={scroll ? 'fixed' : ''} dial={2} row space="between">
          <Hamburger
            className="hamburger-menu"
            onClick={() => {
              if (!navigationStatus) {
                setNavigationStatus(true)
                if (window.innerWidth > 1023) {
                  disableBodyScroll(document.body)
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                setNavigationStatus(false)
                if (window.innerWidth > 1023) {
                  enableBodyScroll(document.body)
                } else {
                  document.body.style.overflow = 'auto'
                }
              }
            }}
          >
            <HamburgerLabel>
              {useVocabularyData('menu', languageCode)}
            </HamburgerLabel>
          </Hamburger>
          <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
            <Logo
              className="logo"
              src={logo.src}
              alt={siteName}
              width={logo.width}
              height={logo.height}
            />
          </Link>
          <Media greaterThan="tabletMedium">
            <CTA
              calendarIcon
              label={useVocabularyData('book', languageCode)}
              onClick={() => {
                if (!modalIBEStatus) {
                  setModalIBEStatus(true)
                  if (window.innerWidth > 1023) {
                    disableBodyScroll(document.body)
                  } else {
                    document.body.style.overflow = 'hidden'
                  }
                } else {
                  setModalIBEStatus(false)
                  if (window.innerWidth > 1023) {
                    enableBodyScroll(document.body)
                  } else {
                    document.body.style.overflow = 'auto'
                  }
                }
              }}
            />
          </Media>
          <Media between={['ipadVertical', 'ipadHorizontal']}>
            <CTA
              calendarIcon
              externalURL={`https://www.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
                languagePrefix ? languagePrefix.toLocaleUpperCase() : 'IT'
              }`}
              label={useVocabularyData('book', languageCode)}
              rel="noopener"
              target="_blank"
            />
          </Media>
          <Media lessThan="ipadVertical">
            {languagesList && languagesList.length > 1 ? (
              <Languages>
                {languagesList
                  .filter((t) => t.pageID === pageID)
                  .map((item, index) => (
                    <LanguageItem key={index} {...item} />
                  ))}
              </Languages>
            ) : null}
          </Media>
        </Foot>
        <Modal className={navigationStatus ? 'open' : ''}>
          <ModalClose
            onClick={() => {
              setNavigationStatus(false)
              if (window.innerWidth > 1023) {
                enableBodyScroll(document.body)
              } else {
                document.body.style.overflow = 'auto'
              }
            }}
          />
          <Navigation
            address={address}
            bookButtonImage={bookButtonImage}
            country={country}
            email={email}
            facebookURL={facebookURL}
            IBEID={IBEID}
            instagramURL={instagramURL}
            languageCode={languageCode}
            languagePrefix={languagePrefix || 'it'}
            offersButton={offersButton}
            offersButtonImage={offersButtonImage}
            menuItems={menuItems}
            phone={phone}
            postalCode={postalCode}
            navigationStatus={navigationStatus}
            locality={locality}
            siteName={siteName}
          />
        </Modal>
        <Media greaterThanOrEqual="ipadVertical">
          <Modal className={modalIBEStatus ? 'open' : ''}>
            <ModalClose
              onClick={() => {
                setModalIBEStatus(false)
                if (window.innerWidth > 1023) {
                  enableBodyScroll(document.body)
                } else {
                  document.body.style.overflow = 'auto'
                }
              }}
              variant="right"
            />
            <ModalIBE
              IBEID={IBEID}
              languagePrefix={languagePrefix || 'it'}
              benefitsTitle={benefitsTitle}
              benefitsDescription={benefitsDescription}
              benefitsChecklist={benefitsChecklist}
              modalStatus={modalIBEStatus}
            />
          </Modal>
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const Head = styled(FlexBox)`
  width: 100%;
  backdrop-filter: blur(12px);
  padding: 0.8125rem 2.25rem;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Contacts = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  line-height: 0.875rem;
  letter-spacing: 0.0313rem;
`

const ContactsItem = styled(FlexBox)`
  margin-right: 1.5rem;
  svg {
    height: 0.6875rem;
  }
`

const ContactsLabel = styled.div`
  opacity: 0.5;
`

const ContactsAnchor = styled.a`
  opacity: 0.5;
  &:hover {
    text-decoration: underline;
  }
`

const Languages = styled.ul`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: 767px) {
    display: block;
    text-align: right;
  }
`

const Foot = styled(FlexBox)`
  width: 100%;
  margin-top: 1.5rem;
  padding: 0 2.25rem;
  position: relative;
  &.fixed {
    animation: fadeInTop 0.3s;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-top: 0;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: fixed;
    top: 0;
    left: 0;
    .logo {
      height: 50px;
      margin-top: 0.5625rem;
    }
    .hamburger-menu {
      &:before,
      &:after {
        background: ${({ theme }) => theme.colors.variants.neutralDark1};
      }
      > div {
        color: ${({ theme }) => theme.colors.variants.neutralDark1};
      }
    }
  }

  @keyframes fadeInTop {
    0% {
      top: -3.75rem;
    }
    100% {
      top: 0;
    }
  }

  @media (max-width: 1199px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (max-width: 1023px) {
    &.fixed {
      .logo {
        height: 40px;
        margin-top: 0.5rem;
      }
    }
  }

  @media (max-width: 767px) {
    &.fixed {
      .logo {
        display: none;
      }
    }
  }
`

const Logo = styled.img`
  width: auto;
  height: 7.5rem;
  margin: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    height: 60px;
  }
`

const Hamburger = styled.div`
  cursor: pointer;
  width: 36px;
  padding-top: 1.75rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    width: 100%;
    height: 2px;
    position: absolute;
    transition: 0.3s ease;
  }
  &:before {
    top: 0;
  }
  &:after {
    top: 0.75rem;
    width: 1.125rem;
  }
  &:hover {
    &:after {
      width: 100%;
    }
    div {
      opacity: 0.3;
    }
  }
`

const HamburgerLabel = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 11px;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 0.0313rem;
  text-transform: uppercase;
  transition: 0.3s ease-out;
`

const CTA = styled(Button)`
  &:hover {
    background: ${({ theme }) =>
      theme.colors.getHoverColor(`${theme.colors.variants.primaryDark1}`)};
    padding-right: 2rem;
    padding-left: 2rem;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`
